<template>
    <div class="container-fluid">
        <Quote/>
        <GoBack next="Mavjud Kategoriyalar"/>
        <div class="row justify-content-center mt-5 pt-0">
            <div class="col-11 col-md-8">
                <div ref="top"></div>
                <table class="table table-hover border">
                    <thead>
                    <tr>
                        <th class="col-3 pl-2 pl-sm-5 d-none d-sm-table-cell">
                            <input
                                v-model="search.id"
                                @keyup.enter="searchCourseInfo"
                                type="number"
                                placeholder="Id"
                                style="width: 130px;height: 28px;border-radius: 5px"
                            />
                        </th>
                        <th class="col-3 pl-4 pl-sm-2 col-sm-2">
                            <input
                                v-model="search.name"
                                @keyup.enter="searchCourseInfo"
                                type="text"
                                placeholder="Kategoriya nomi"
                                style="width: 130px;height: 28px;border-radius: 5px"
                            />
                        </th>
                        <th class="col-2">
                            <b-button
                                v-b-tooltip.hover
                                title="Izlash"
                                class="border-0 p-0 ms-4 ms-md-5 me-1 mb-1"
                                style="background-color: #E5E5E5; font-size:1.6vw;"
                            >
                                <img
                                    @click="searchCourseInfo"
                                    style="cursor: pointer; font-size:1vw;"
                                    src="../assets/search-icon.png"
                                    alt=""
                                />
                            </b-button>
                            <b-button
                                v-b-tooltip.hover
                                title="Tozalash"
                                class="border-0 p-0 ms-1 mb-1"
                                style="background-color: #E5E5E5; font-size:1.6vw;"
                            >
                                <img
                                    @click="resetSearchTypeInfo"
                                    style="cursor: pointer; font-size:1vw;"
                                    src="../assets/eraser-icon.png"
                                    alt=""
                                />
                            </b-button>
                        </th>
                    </tr>
                    </thead>

                    <tbody>
                    <tr
                        class="pl-3 pt-3 pb-3"
                        v-for="category in getCategories"
                        :key="category.id"
                    >
                        <td class="d-none d-sm-table-cell pl-5">{{ category.id }}</td>
                        <td class="pl-4 pl-sm-3">{{ category.name }}</td>
                        <td>
                            <router-link
                                :to="'/change-category/' + category.id"
                                :course="category"
                                class="ms-4 ms-md-5 me-2"
                            >
                                <img src="../assets/pen.png" alt=""/>
                            </router-link>
                            <button
                                @click="showModal(category.id)"
                                type="button"
                                style="border: none; background-color: white; font-size:1.5vw;"
                            >
                                <img src="../assets/trash.png"/>
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div>
                    <b-pagination
                        class="mt-0 mb-5"
                        v-model="page"
                        pills
                        align="center"
                        :per-page="20"
                        :total-rows="getCourseTypesTotal"
                        @input="fetchCategoriesByPage"
                        :click-handler="fetchCategoriesByPage   "
                    >
                    </b-pagination>
                </div>
            </div>
        </div>

        <div>
            <b-modal ref="modal-success" hide-footer title="Kadirov.Dev">
                <div class="d-block text-center">
                    <h3>Ushbu kategoriyani rostdan ham o'chirmoqchimisiz?</h3>
                </div>
                <b-button
                    class="mt-3 p-1" style="width: 100px"
                    variant="btn btn-primary"
                    size="lg"
                    @click="hideErrorModal"
                >
                    Yo'q
                </b-button>
                <b-button
                    class="mt-3 p-1 float-end"
                    style="width: 100px"
                    variant="btn btn-danger"
                    size="lg"
                    @click="deleteCourse"
                >
                    Ha
                </b-button>
            </b-modal>
        </div>
        <b-overlay :show="show" no-wrap>
        </b-overlay>
    </div>
</template>

<script>
import GoBack from "../components/GoBack";
import Quote from "../components/Quote";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "ExistingCourseTypes",
    components: {
        Quote,
        GoBack
    },
    computed: {
        ...mapGetters(['getCourseTypesTotal', 'getCategories', 'getSearchCategory'])
    },
    data() {
        return {
            show: true,
            page: 1,
            categoryId: null,
            search: {
                id: null,
                name: '',
                page: 1,
            }
        }
    },
    watch: {
        '$route.params.page': {
            handler: function (val) {
                this.currentPage = val ? val : 1
                this.fetchCategoryPagination(this.page)
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        ...mapActions([
            'deleteCategory',
            'fetchCategoryPagination',
            'searchCategory',
            'fetchCategories'
        ]),
        searchCourseInfo() {
            this.show = true

            this.searchCategory(this.search)
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => {
                    this.show = false
                });
        },
        resetSearchTypeInfo() {
            Object.assign(this.$data, this.$options.data.call(this));
            this.searchCourseInfo()
        },
        fetchCategoriesByPage() {
            this.$router.push('/exist-categories-list/page/' + this.page)
            this.$refs.top.scrollIntoView()
        },
        deleteCourse() {
            this.deleteCategory(this.categoryId)
                .then(() => {
                    this.$refs['modal-success'].hide()
                    this.fetchCategories()
                })
        },
        showModal(id) {
            console.log(id)
            this.categoryId = id
            this.$refs['modal-success'].show()
        },
        hideErrorModal() {
            this.$refs['modal-success'].hide()
        }
    },
    mounted() {
        this.show = true
        this.fetchCategories()
        this.show = false
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Comfortaa&family=Open+Sans:wght@400;600&display=swap');

* {
    margin: 0;
    padding: 0;
}

.agree-btn {
    font-size: 12px;
    font-family: Comfortaa, cursive;
    padding: 5px;
}

input {
    border: 1px solid black;
    margin-bottom: 5px;
}

input::placeholder {
    font-size: 12px;
    padding: 0.5rem;
}

input:focus {
    border: none;
    outline: 2px solid #b505b4;
}

img {
    width: 20px;
    height: 20px;
    position: relative;
}

.row {
    margin-top: 111px;
}

table {
    margin-bottom: 48px;
}

table th {
    height: 60px;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    text-align: left;
    background-color: #E5E5E5;
    color: rgba(0, 0, 0, 0.5);
}

tbody tr {
    height: 60px;
    vertical-align: text-top;
}

table tr select {
    margin-right: 10px;
    border: none;
}

.change {
    text-decoration: none;
    padding: 10px;
    border-radius: 5px;
}

table tbody tr td {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 1px;
    background-color: #FFFFFF;
    color: #000000;
}
</style>